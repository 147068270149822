import React, { useState } from "react";
import Layout from "../layouts/Layout";
import SiteMetadata from "../components/SiteMetaData";
import { BuyMeACoffee } from "../components/Utils";
import Counter from "../components/Counter";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const KontaktPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    subject: "",
    text: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": event.target.getAttribute("name"),
        ...formState,
      }),
    })
      .catch((error) => alert(error))
      .then(document.getElementById("form").reset())
      .then(onOpen);
  };

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const Form = () => {
    return (
      <form
        name="contact v3"
        id="form"
        data-netlify="true"
        method="post"
        onSubmit={handleSubmit}
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact v3" />
        <div hidden>
          <input name="bot-field" />
        </div>
        <div className="sm:flex">
          <div className="sm:mr-3">
            <label>
              <div>Name </div>
              <input
                type="text"
                name="name"
                className="border border-gray-200 dark:border-gray-400 rounded-md w-full sm:w-48 mb-4 p-2 dark:bg-gray-700"
                placeholder="Dein Name"
                required
                onChange={handleChange}
              />
            </label>
          </div>
          <div>
            <label>
              <div>E-Mail </div>
              <input
                type="email"
                name="email"
                className="border border-gray-200 dark:border-gray-400 rounded-md w-full sm:w-80 mb-4 p-2 dark:bg-gray-700"
                placeholder="Deine E-Mail"
                required
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
        <div>
          <label>
            <div>Betreff </div>
            <input
              type="text"
              name="subject"
              className="border border-gray-200 dark:border-gray-400 rounded-md w-full mb-4 p-2 dark:bg-gray-700"
              placeholder="Dein Betreff"
              required
              onChange={handleChange}
            />
          </label>
        </div>
        <div>
          <label>
            Nachricht{" "}
            <textarea
              rows="4"
              cols="50"
              name="text"
              className="border border-gray-200 dark:border-gray-400 rounded-md w-full mb-3 h-16 p-2 dark:bg-gray-700"
              placeholder="Deine Nachricht"
              required
              onChange={handleChange}
            />
          </label>
        </div>
        <button type="submit" className="submitButton">
          Abschicken
        </button>
      </form>
    );
  };

  const Sidebar = () => {
    return (
      <div>
        <div className="hidden md:contents">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-3 mb-4">
            <div className="text-xl mb-5">Trivia</div>
            <Counter />
          </div>
        </div>
        <BuyMeACoffee />
      </div>
    );
  };

  const ModalSubmit = () => {
    return (
      <Modal isOpen={isOpen} onClose={onClose} preserveScrollBarGap>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dankeschön</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Deine Nachricht wurde versendet!</ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    );
  };

  return (
    <Layout unlocked={true}>
      <SiteMetadata title="Kontakt" description="Kontaktiere uns." />
      <div className="bg-gray-50 pb-6 flex-grow dark:bg-gray-900 dark:text-white">
        <div className="container">
          <div className="text-center text-4xl py-6 lg:hidden">Kontakt</div>
          <div className="md:grid grid-cols-4 gap-3">
            <div className="md:col-span-3 mb-8 md:mb-0">
              <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden transform p-3 h-full">
                {Form()}
              </div>
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
      <ModalSubmit />
    </Layout>
  );
};

export default KontaktPage;
